<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card>
                    <v-card-title class="title">
                        <v-icon>layers</v-icon>
                        Account Group List
                        <v-spacer></v-spacer>
                        <add-button permission="subject-read" @action="form.dialog = true">New Account Group
                        </add-button>
                    </v-card-title>

                    <v-card flat>
                        <v-card-title class="title">
                            <v-flex xs4>
                                <v-text-field
                                        hint="Atleast 3 characters"
                                        flat
                                        color="primary"
                                        class="pa-0"
                                        label="Search By Name"
                                        v-model="searchAH"
                                        @input="searchAccountHeads"
                                />
                            </v-flex>

                        </v-card-title>
                    </v-card>

                    <v-data-table :headers="headers"
                                  :items="form.items.data"
                                  :rows-per-page-items="rowsPerPageItems"
                                  :pagination.sync="pagination"
                                  :total-items="form.items.meta.total"
                    >
                        <template slot="items" slot-scope="props">
                            <td>{{ ++props.index }}</td>
                            <td class="text-xs-left">{{ props.item.name }}</td>
                            <td class="text-xs-left">{{ props.item.parent_name }}</td>
                            <td class="text-xs-left">{{ props.item.description }}</td>
                            <td class="text-xs-left">
                                <span v-if="props.item.company_id">
                                <edit-button permission="subject-read"
                                             @agree="form.edit(props.item),editData(props.item)"/>
                                <delete-button permission="section-create" @agree="form.delete(props.item.id)"/>
                                    </span>
                            </td>
                        </template>
                        <template slot="no-data">
                            <v-alert :value="true" color="error" icon="warning">
                                No record found.
                            </v-alert>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
        <v-dialog v-model="form.dialog" persistent max-width="500px">
            <v-card>
                <v-card-title class="title primary white--text">Account Group Information</v-card-title>
                <v-card-text>
                    <v-form ref="form" @submit.prevent="save" @keydown.native="form.errors.clear($event.target.name)"
                            @keyup.enter="save" lazy-validation>
                        <v-container grid-list-md>
                            <v-layout row wrap>
                                <v-flex xs12>
                                    <v-text-field v-model="form.name" name="name" label="Name"
                                                  :error-messages="form.errors.get('name')" :height="25"
                                                  autocomplete="off"/>
                                </v-flex>
                                <!--<v-flex xs12>-->
                                <!--<v-select v-model="form.parent_id" name="parent_id" :items="accountHeads"-->
                                <!--label="Select Account Head" :height="25"/>-->
                                <!--</v-flex>-->
                                <v-autocomplete
                                        v-model="form.parent_id"
                                        :items="items"
                                        :search-input.sync="search"
                                        hide-no-data
                                        item-text="Name"
                                        item-value="id"
                                        label="Parent Account Group"
                                        placeholder="Start typing to Search"
                                ></v-autocomplete>
                                <v-flex xs12 sm12>
                                    <v-textarea name="description" v-model="form.description" label="Description"
                                                :error-messages="form.errors.get('description')"/>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="error" outline flat @click="form.dialog = false, form.reset()">Cancel</v-btn>
                    <v-btn color="warning" outline @click="save(true)">Save And Continue</v-btn>
                    <v-btn color="success" outline @click="save(false)">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'

    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();

    export default {
        data: () => ({
            form: new Form({
                name: '',
                description: '',
                company_id: '',
                parent_id: ''
            }, '/api/account-head'),
            searchAH: null,
            search: null,
            isLoading: false,
            rowsPerPageItems: [5, 10, 25, 50, 75, 100],
            pagination: {
                rowsPerPage: 10,
            },
            entries: [],
            accountHeads: [],
            headers: [
                {text: '#', align: 'left', sortable: false, value: 'id', width: '20px'},
                {text: 'Name', value: 'name', align: "left", width: '30%'},
                {text: 'Parent', value: 'parent', align: "left", width: '20%', sortable: false},
                {text: 'Description', value: 'description', align: "left"},
                {text: 'Actions', value: 'action', sortable: false, align: 'right', width: 120}
            ]
        }),

        computed: {
            ...mapState(['batch']),
            items() {
                return this.entries.map(entry => {
                    return {Name: entry.name, id: entry.id};
                })
            }
        },

        watch: {
            'pagination': function () {
                this.get();
            },
            'batch': function (value) {
                this.get();
            },
            search(val) {
                if (!val) return;
                // if (this.isLoading) return;

                this.isLoading = true;
                this.$rest.get('/api/account-head?search=' + val)
                    .then(({data}) => {
                        // this.entries = data.data
                        this.entries = data.data.map(res => {
                            return {name:res.name , id:res.id}
                        })
                    }).catch(err => {
                }).finally(() => (this.isLoading = false))
            }
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&search=' + this.searchAH;
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                    this.accountHeads = data.data.map(item => ({value: item.id, text: item.name}));
                })
            },
            editData(data) {
                this.entries = [];
                this.entries.push({name:data.parent_name,id:data.parent_id})

            },

            save(tag) {
                this.form.closeModal = !tag;
                // this.form.parent_id = this.form.parent_id.id;
                this.form.store();
            },
            searchAccountHeads: function (v) {
                if (v.length > 2 || !v.length) this.get()
            },

        },
        filters: {
            getParent(parentId, accountHeads) {
                if (parentId && accountHeads.length > 0) {
                    let item = accountHeads.find(item => item.value === parentId);
                    // return [undefined,'', null].includes();
                    if (![undefined, '', null].includes(item)) return item.text;
                }
                return '-'
            }
        },

    }
</script>
<style lang="scss">
</style>

